import React from "react"
import { Container, Row, Col } from "react-bootstrap";
import {css} from '@emotion/core';

import Layout from '../../components/layout';
import SubCover from '../../components/sub-cover';
import PageContent from '../../components/page-content';
import {loadMap} from '../../components/map-loader';

export default class extends React.Component {
  componentDidMount() {
    loadMap();
  }
  render() {
    return (
      <Layout pageTitle="会社概要">
        <SubCover
          title="about"
          caption="会社概要"
          image="about-cover.jpg"
        />

        <PageContent>
          <Container fluid className="no-padding">
            <Row>
              <Col md={4}>
                <h4>事業内容</h4>
                音とテクノロジーに関するソリューションの提供<br />
                音・音楽の活用に関する調査・開発<br />
                新しいニーズに応じた技術開発・サービスの提供
              </Col>
              <Col md={4}>
                <h4>コーポレートパートナー</h4>
                東京芸術大学<br />
                addict
              </Col>
              <Col md={4}>
                <h4>求人</h4>
                プロデューサー<br />
                アートマネージャー<br />
                ソフトウェアエンジニア<br />
                一般事務
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <h4>会社情報</h4>
                会社名：株式会社coton（カブシキガイシャコトン）<br />
                所在地：神奈川県 川崎市高津区 久本1-15-5 リベラルY 101号室<br />
                設立：2019年7月1日<br />
                役員：代表取締役　濱 透<br />
                資本金：600万円
              </Col>
              <Col md={8}>
                <h4>業務内容</h4>
                デジタル技術における音と振動、音響心理、音楽心理に特化した技術の企画、 開発及び販売<br />
                アプリケーションの企画、開発及び販売<br />
                特許技術の開発及び活用<br />
                心理とデータの関連性に関する独自の根拠研究のサービス<br />
                各種マーケティングに関する情報の収集企画に関する業務<br />
                産学連携による研究に基づく事業<br />
                教育事業の企画、運営及びコンサルティング<br />
                インターネットを使った学習サービスの開発販売<br />
                デジタル技術を活用した研究、商材の企画、開発及び販売<br />
                前各号に附帯する一切の事業
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <h4>アクセス</h4>
                〒213-0011 神奈川県 川崎市高津区 久本1-15-5 リベラルY 101号室<br />
                TEL：050-5328-5711
              </Col>
              <Col md={8}>
                <div 
                  id="map"
                  css={css`
                    width: 100%;
                    height: 400px;
                    margin-top: 40px;
                  `}
                ></div>
              </Col>
            </Row>
          </Container>

        </PageContent>
      </Layout>
    );
  }
};